<template>
	<section class="permission">
		<!--工具条-->
		<div class="toolbar flex-between">
			<el-form :inline="true" :model="form">
				<el-form-item label="账号查询">
					<el-input v-model.trim="form.username" placeholder="账号查询" clearable></el-input>
				</el-form-item>
				<el-form-item label="店铺名查询">
					<el-input v-model.trim="form.name" placeholder="店铺名查询" clearable></el-input>
				</el-form-item>
				<el-form-item label="渠道状态">
					<el-select v-model="form.bind_status" placeholder="请选择类型" clearable class="w120">
						<el-option v-for="item in typeList" :key="item.value" :label="item.txt" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<!-- <el-form-item label="关联商户数">
					<el-select v-model="form.channel" placeholder="关联商户数" clearable>
						<el-option v-for="item in options" :key="item.label" :label="item.value" :value="item.label"></el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item>
					<el-button type="primary" @click="getList(1)" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
			<el-button type="success" @click="goTherAdmin">支付进件后台</el-button>
		</div>

		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" style="width: 100%;">	
			<el-table-column prop="store_id" label="店铺id" sortable width="100" align="center">
			</el-table-column>
			<el-table-column prop="username" label="店铺登录账号" width="150" align="center">
			</el-table-column>
			<el-table-column prop="name" label="店铺名称" width="180">
			</el-table-column>
			<el-table-column prop="trading_count" label="已关联商户数" width="150" align="center">
			</el-table-column>
			<el-table-column prop="channel_list" label="已开通通道" >
			</el-table-column>
			<!-- <el-table-column label="已开通通道">
				<template slot-scope="scope">
					<span class="mr4" v-if="scope.row.huifu">汇付,</span>
					<span class="mr4" v-if="scope.row.shouyashou">收呀收,</span>
					<span class="mr4" v-if="scope.row.taocao">淘彩,</span>
					<span class="mr4" v-if="scope.row.xiaoju">小菊</span>
				</template>
			</el-table-column> -->
			<el-table-column label="操作" align="center">
				<template slot-scope="scope">
					<el-button  type="primary" plain size="small" @click="modify(scope.row)" >支付修改</el-button>				
				</template>
			</el-table-column>
		</el-table>
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<!-- <span>共计商户：7家,收款总额: 1688834.59元</span> -->
			<!--分页-->
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="page"
					:page-sizes="pagesizes"
					:page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total">
			</el-pagination>
		</el-col>
		<el-dialog :title="midifTit + '支付信息'" :visible.sync="dialogShow" width="960px">
			<div>
				<el-table class="mb10" :data="tradingInfo" style="width: 100%;" max-height="400" @selection-change="handleSelectionChange">
					<el-table-column type="selection" width="55" align="center">
					</el-table-column>
					<el-table-column prop="merchant_code" label="商户编号" width="140" >
					</el-table-column>
					<el-table-column prop="merchant_name" label="商户名称" width="110" >
					</el-table-column>
					<el-table-column prop="channel" label="通道" width="80" align="center">
					</el-table-column>
					<el-table-column prop="channel_type" label="类型" width="90" align="center">
					</el-table-column>
					<!-- <el-table-column label="类型" width="60" >
						<template slot-scope="scope">
							{{ scope.row.channel_code == 'weixin' ? '微信':'支付宝' }}
						</template>
					</el-table-column> -->
					<el-table-column prop="rate" label="支付费率" width="80" align="center">
					</el-table-column>	
					<el-table-column prop="cycle" label="结算周期" width="80" align="center">
					</el-table-column>	
					<el-table-column label="渠道状态" width="80" align="center">
						<template slot-scope="scope">
							{{ scope.row.m_state ? '开启':'关闭' }}
						</template>
					</el-table-column>			
					<el-table-column label="操作" width="80" align="center">
						<template slot-scope="scope">
							<!-- @change="setSwitch(scope.row.trad_id)" -->
							<el-switch v-model="scope.row.m_state"></el-switch>
						</template>
					</el-table-column>
					<el-table-column prop="seq" label="排序" >
						<template slot-scope="scope">
							<el-input class="mInput" v-model="scope.row.seq"></el-input>
						</template>
					</el-table-column>
				</el-table>
				<div class="red">注意：所修改的选项需要勾选才会提交修改！</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogShow = false" style="width:130px">取 消</el-button>
				<el-button type="primary" @click="getconfirmAction" style="width:130px">提交信息</el-button>
			</span>
		</el-dialog>
	</section>
</template>

<script>
let _t = undefined
import { getStroeList, getChannelList, getStoreTradingInfo, updateTradingInfo } from '../../api/api';
export default {
	data() {
		return {
			midifTit: '',
			modifyInfo:{},
			dialogShow:false,
			form:{
				username:'', 
				name:'',
				bind_status: ''
			},
			typeList:[
				{
					value: '',
					txt: '全部'
				},
				{
					value: 1,
					txt: '已绑定'
				},
				{
					value: 0,
					txt: '未绑定'
				}
			],
			options: [
				{
					value: '全部',
					label: ''
				},
				{
					value: '2',
					label: '2'
				},
				{
					value: '1',
					label: '1'
				},
				{
					value: '0',
					label: '0'
				}
			],
			listData: [],
			tradingInfo: [],
			//分页
			total: 0,
			page: 1,
			pagesize: 10,//每页条数
			pagesizes: [10, 20, 50, 100],//可选条数
			listLoading: false,
			selection: []
		}
	},
	mounted() {
		_t = this
		_t.getList();
		_t.getChannelList();
	},
	methods: {
		goTherAdmin(){
			console.log('goTherAdmin', process.env.NODE_ENV, process.env)
			window.open(process.env.VUE_APP_ADMIN_JINJIAN, '_blank');
			// window.location.href = "http://admin.liezhen4231.com/houtai/index.html"
		},
		handleSelectionChange(val) {
			_t.selection = val;
		},
		//获取通道列表
		async getChannelList(){
			getChannelList().then((res) => {
				console.log('getChannelList', res)
				// this.options
			})			
			console.log( this.options );
		},
		//获取用户列表
		getList(type) {
			if(type) this.page = 1
			let params = {...this.form, page: this.page, pagesize: this.pagesize }
			this.listLoading = true;
			getStroeList(params).then((res) => {
				this.listLoading = false;
				if(res.code == 1){
					this.total = res.data.total;
					this.listData = res.data.list;
					console.log( this.listData );
				}else {
					this.$notify({
						title: '错误',
						message: res.msg,
						type: 'error'
					});
				}
			});
		},
		async modify( obj ){
			_t.midifTit = obj.name
			_t.modifyInfo.id = obj.store_id
			await _t.getTradInfo(obj.store_id)
			this.dialogShow = true;
		},
		async getTradInfo(id){
			await getStoreTradingInfo({store_id:id}).then((res) => {
				res.code == 1 && (_t.tradingInfo = res.data)
			})
		},
		//分页
		handleSizeChange(val) {
			this.pagesize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getList();
		},
		//修改
		async getconfirmAction() {
			console.log( 'getconfirmAction', _t.selection );
			updateTradingInfo({trad_list:_t.selection}).then((res) => {
				if(res.code == 1){
					this.$message.success('修改成功！');
					this.dialogShow = false;
					this.getList();	
				}else{
					this.$notify({
						title: '修改失败',
						message: res.msg,
						type: 'error'
					});
				}
			})
		}			
	}
}

</script>

<style lang="scss">
	@import "../../styles/permission.scss";
	.mInput{
		.el-input__inner{ width: 60px; text-align: center; height:32px; line-height:32px;  }
	}
</style>